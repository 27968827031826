import React from 'react';
import './shapeHex.css';

const ShapeHex = (props) => {
  const calculateStyle = () => {
    const style = props.style;
    const marginLeft = props.style.width / 2;
    return {
      ...props.style,
      marginLeft: marginLeft,
    };
  };

  return (
    <div className={'shape-hex'} style={calculateStyle()}>
      <div
        className={'shape-text'}
        style={{
          width: props.style.width * 2,
          left: -props.style.width / 2,
          top: 5,
          lineHeight: props.style.height + 'px',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default ShapeHex;
