import React from 'react';
import { Link } from 'gatsby';
import Toggle from './Toggle';
import Helmet from 'react-helmet';

import { rhythm, scale } from '../utils/typography';
import sun from '../assets/sun.png';
import moon from '../assets/moon.png';
import firework from '../assets/firework.png';
import ShapeHex from './shapeHex';

const Layout = ({ location, title, children }) => {
  const renderHeader = () => {
    const rootPath = `${__PATH_PREFIX__}/`;

    const reg = new RegExp('^/(page/[0-9]+){0,1}$');
    if (reg.test(location.pathname)) {
      return (
        <ShapeHex
          style={{
            background: 'var(--main-blue)',
            height: 80,
            width: 46,
          }}
        >
          <h1 style={{ margin: '20px 0' }}>
            <Link
              style={{
                fontSize: 13,
                boxShadow: 'none',
                textDecoration: 'none',
                color: '#fff',
              }}
              to={'/'}
            >
              {title}
            </Link>
          </h1>
        </ShapeHex>
      );
    } else {
      return (
        <ShapeHex
          style={{
            background: 'var(--mainBg)',
            height: 80,
            width: 46,
          }}
        >
          <h3 style={{ margin: '25px 0' }}>
            <Link
              style={{
                fontSize: 13,
                boxShadow: 'none',
                textDecoration: 'none',
                color: 'var(--textTitle)',
              }}
              to={'/'}
            >
              {title}
            </Link>
          </h3>
        </ShapeHex>
      );
    }
  };
  const render = () => {
    return (
      <div
        style={{
          color: 'var(--textNormal)',
          background: 'var(--bg)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
          minHeight: '100vh',
        }}
      >
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Helmet
            meta={[
              {
                name: 'theme-color',
                content: '#ffa8c5',
              },
            ]}
          />
          <header
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              marginBottom: '1rem',
              position: 'relative',
              height: '8px',
              zIndex: 2,
              background: `repeating-linear-gradient(106deg, var(--mainBg), var(--mainBg) 25%, var(--main-purple) 0px, var(--main-purple) 50%, var(--main-red) 0px, var(--main-red) 75%, var(--main-blue) 0px, var(--main-blue))`,
            }}
          >
            {renderHeader()}
            <ShapeHex
              style={{
                background: 'var(--main-red)',
                height: 56,
                width: 32,
                left: 80,
                top: 46,
              }}
            >
              <Link
                to={'/'}
                style={{ boxShadow: 'none', color: '#fff', padding: '0 8px' }}
              >
                recent
              </Link>
            </ShapeHex>
            <ShapeHex
              style={{
                background: 'var(--main-purple)',
                height: 56,
                width: 32,
                top: 16,
                left: 135,
              }}
            >
              <Link
                to={'/tag/react'}
                style={{ boxShadow: 'none', color: '#fff', padding: '0 8px' }}
              >
                #react
              </Link>
            </ShapeHex>
            {/* {this.state.theme !== null ? (
            <Toggle
              icons={{
                checked: (
                  <img
                    src={moon}
                    width="16"
                    height="16"
                    role="presentation"
                    style={{ pointerEvents: 'none' }}
                  />
                ),
                unchecked: (
                  <img
                    src={sun}
                    width="16"
                    height="16"
                    role="presentation"
                    style={{ pointerEvents: 'none' }}
                  />
                ),
              }}
              checked={this.state.theme === 'dark'}
              onChange={e =>
                window.__setPreferredTheme(
                  e.target.checked ? 'dark' : 'light'
                )
              }
            />
          ) : (
            <div style={{ height: '24px' }} />
          )} */}
          </header>
          {children}
        </div>
      </div>
    );
  };
  return render();
};

export default Layout;
